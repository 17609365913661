import { BaseController } from "@stimulus-library/utilities";
import { useCollectionEventListener } from "@stimulus-library/mixins";
export class TableSortController extends BaseController {
    static values = { startSort: Number };
    _lastIndex = null;
    _reverse = false;
    get _tableHead() {
        let head = this.el.tHead;
        if (head == null) {
            throw new Error('Expected table to have a <thead> element.');
        }
        return head;
    }
    get _tableHeaders() {
        let rows = this._tableHead.rows;
        if (rows.length == 0) {
            throw new Error('Expected table to have a <thead> element with at least one row.');
        }
        return Array.from(rows[0].cells);
    }
    get _tableBody() {
        return this.el.tBodies[0];
    }
    get _tableRows() {
        return Array.from(this._tableBody.rows);
    }
    connect() {
        requestAnimationFrame(() => {
            useCollectionEventListener(this, this._tableHeaders, "click", this.sort);
            if (this.hasStartSortValue) {
                this.sort(this._headerCellByIndex(this.startSortValue));
            }
        });
    }
    sort(event_or_target) {
        let headerCell;
        if (event_or_target instanceof Event) {
            event_or_target.preventDefault();
            headerCell = event_or_target.target;
        }
        else {
            headerCell = event_or_target;
        }
        let headerCellIndex = this._indexOfHeaderCell(headerCell);
        if (headerCell.dataset.sortable == "false") {
            return;
        }
        if (headerCell.dataset.sort == "asc") {
            this._reverse = true;
            this._otherHeaderCells(headerCell).forEach(cell => delete cell.dataset.sort);
            headerCell.dataset.sort = "desc";
            this._sortByColumn(headerCellIndex);
        }
        else {
            this._reverse = false;
            this._otherHeaderCells(headerCell).forEach(cell => delete cell.dataset.sort);
            headerCell.dataset.sort = "asc";
            this._sortByColumn(headerCellIndex);
        }
    }
    _indexOfHeaderCell(cell) {
        return this._tableHeaders.indexOf(cell);
    }
    _headerCellByIndex(index) {
        let cell = this._tableHeaders.at(index);
        if (!cell) {
            throw new Error(`No cell at index ${index}`);
        }
        return cell;
    }
    _otherHeaderCells(cell) {
        return Array.from(this._tableHeaders).filter(otherCell => otherCell != cell);
    }
    _sortByColumn(index) {
        let frag = document.createDocumentFragment();
        let rows = this._tableRows;
        let newRows = rows.sort((row, otherRow) => {
            let cells = Array.from(row.cells);
            let otherCells = Array.from(otherRow.cells);
            // TODO: Handle colspans?
            let x = cells[index]?.dataset?.sortValue || cells[index]?.innerText || "";
            let y = otherCells[index]?.dataset?.sortValue || otherCells[index]?.innerText || "";
            let sortVal = x.localeCompare(y, "en", { sensitivity: "base", numeric: true, caseFirst: "upper" });
            if (row.dataset.sortTop || otherRow.dataset.sortBottom) {
                if (row.dataset.sortTop && otherRow.dataset.sortTop) {
                    return sortVal;
                }
                return -1;
            }
            if (row.dataset.sortBottom || otherRow.dataset.sortTop) {
                if (row.dataset.sortBottom && otherRow.dataset.sortBottom) {
                    return sortVal;
                }
                return 1;
            }
            if (this._reverse) {
                return sortVal > 0 ? -1 : 1;
            }
            return sortVal;
        });
        newRows.forEach(row => frag.appendChild(row));
        this._tableBody.innerHTML = "";
        this._tableBody.appendChild(frag);
        this._lastIndex = index;
    }
}
